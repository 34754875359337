/* eslint-disable @typescript-eslint/no-unused-vars */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProviderProfileComponent } from './provider-profile.component';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { ProviderStarRatingModule } from '../../provider-result/provider-star-rating/provider-start-rating.module';
import { NoSchedulingTextModule } from '../../no-scheduling-text/no-scheduling-text.module';

@NgModule({
  declarations: [ProviderProfileComponent],
  exports: [ProviderProfileComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatExpansionModule,
    ProviderStarRatingModule,
    NoSchedulingTextModule,
  ],
})
export class ProviderProfileModule {}
