<!--/*  Start of old provider detail page Html
When the new experience goes live,please remove this html */-->
<div *ngIf="!isNewProviderDetailExperience" class="provider-detail-info-container">
  <div class="provider-info-container" #mainContent tabindex="0">
    <div class="provider-profile-image">
      <div [ngClass]="isSquare ? 'provider-square' : ''">
        <img tabindex="0" width="100%" height="185px" [src]="imageUrl" alt="AHN Provider Logo" />
      </div>
      <div *ngIf="employed" class="profile-bcg-text">
        <img
          src="../../../../../assets/small_ahn_logo.svg"
          height="17px"
          width="18px"
          alt="AHN Provider"
        />
        <div class="ahn-provider-text">AHN Provider</div>
      </div>
    </div>
    <div class="provider-profile-info">
      <div class="provider-information">
        <div class="provider-display-name sofia-pro-semi-bold">
          <span tabindex="0">{{ displayName }}</span>
        </div>
        <div class="provider-text roboto-regular provider-specialty">{{ signatureSpecialty }}</div>
        <atlas-provider-star-rating
          [ratingCount]="this.ratingCount"
          [ratingValue]="this.ratingValue"
        ></atlas-provider-star-rating>
        <div style="display: flex; flex-direction: row; padding-top: 0">
          <div
            [ngClass]="seeAll ? 'conditions-text-limited' : 'conditions-text-all'"
            *ngIf="conditionsDisplay !== ''"
            style="flex: 1"
          >
            <span
              ><span><strong>Expertise: </strong></span>{{ conditionsText }}</span
            >
            <a
              (click)="onClick('provider-expertises')"
              (keydown.enter)="onClick('provider-expertises')"
              ><span
                *ngIf="conditionsDisplay.length >= maxCharacterCount"
                class="see-all-text"
                aria-label="See All"
                tabindex="0"
                >{{ 'See all...' }}</span
              ></a
            >
          </div>
        </div>
        <div *ngIf="this.outPatientEnabled === 'true'">
          <div class="make-appointment-container" *ngIf="schedulable === 'true'">
            <button
              mat-button
              class="make-appointment-button"
              (click)="onMakeAppointment()"
              (keydown.enter)="onMakeAppointment()"
              [disableRipple]="true"
            >
              MAKE AN APPOINTMENT
            </button>
            <div class="make-appointment-container"></div>
          </div>
          <div class="make-appointment-container">
            <div class="provider-phone-call">
              <a href="tel:{{ this.phoneNumber }}">{{ this.phoneNumber }}</a>
            </div>
          </div>
        </div>
        <div *ngIf="this.outPatientEnabled === 'false'">
          <atlas-no-scheduling-text></atlas-no-scheduling-text>
        </div>
      </div>
    </div>
  </div>
</div>
<!--/*  End of old provider detail page Html */-->

<!--/* New Provider Detail Experience */-->
<div *ngIf="isNewProviderDetailExperience" class="provider-detail-page-new">
  <div
    [ngClass]="isMobile ? 'provider-mobile-view' : 'provider-desktop-view'"
    #mainContent
    tabindex="0"
  >
    <div class="profile-conatiner">
      <div
        [ngClass]="isSquare ? 'provider-square' : 'provider-regular'"
        class="provider-profile-image"
      >
        <div
          [ngClass]="noProviderImage && isMobile ? 'image-no-provider' : ''"
          class="provider-image"
        >
          <img
            *ngIf="!isMobile"
            [ngClass]="noProviderImage ? 'desktop-image-no-provider' : ''"
            class="desktop-image"
            [src]="roundImageUrl"
            alt="AHN Provider Logo"
          />
          <img
            *ngIf="isMobile"
            class="mobile-image"
            [src]="imageUrl"
            alt="AHN Provider Mobile Logo"
          />
        </div>
        <div *ngIf="isMobile" class="profile-bcg-text">
          <img
            src="../../../../../assets/small_ahn_logo.svg"
            height="17px"
            width="18px"
            alt="AHN Provider"
          />
          <div class="ahn-provider-text">AHN Provider</div>
        </div>
      </div>
      <div class="provider-profile-info">
        <div class="provider-profile-info-right-container">
          <div class="provider-information">
            <div class="profile-bcg-text">
              <img
                src="../../../../../assets/small_ahn_logo.svg"
                height="17px"
                width="18px"
                alt="AHN Provider"
              />
              <div class="ahn-provider-text">AHN Provider</div>
            </div>
            <div class="provider-display-name sofia-pro-semi-bold">
              <h2>{{ displayName }}</h2>
            </div>
            <div class="provider-text roboto-regular provider-specialty">
              {{ signatureSpecialty }}
            </div>

            <span
              (click)="isCommentsClick(true)"
              class="provider-ratings"
              *ngIf="ratingValue !== '0'"
            >
              <span class="provider-ratings-value">{{ ratingValue }}</span>
              <span class="provider-ratings-value material-icons-outlined">star</span>
            </span>
            <span class="provider-ratings" *ngIf="ratingCount !== '0'">
              <a
                class="provider-ratings-comments"
                (click)="isCommentsClick(true)"
                title="See Comments"
                >({{ commentCount }} comments)</a
              >
            </span>

            <div *ngIf="this.outPatientEnabled === 'true' && !isMobile">
              <div class="provider-phone-call">
                <a href="tel:{{ this.phoneNumber }}" title="{{ this.phoneNumber }}">{{
                  this.phoneNumber
                }}</a>
              </div>
              <div class="make-appointment-container" *ngIf="schedulable === 'true'">
                <button
                  mat-button
                  class="make-appointment-button"
                  (click)="onMakeAppointment()"
                  (keydown.enter)="onMakeAppointment()"
                  [disableRipple]="true"
                  title="Make an Appointment"
                >
                  MAKE AN APPOINTMENT
                </button>
              </div>
            </div>
            <div *ngIf="this.outPatientEnabled === 'false'">
              <atlas-no-scheduling-text [colorBlue]="false"></atlas-no-scheduling-text>
            </div>
          </div>
          <!--/* Mobile Only */-->
          <div
            class="make-appointment-container"
            *ngIf="this.outPatientEnabled === 'true' && isMobile"
          >
            <div *ngIf="schedulable === 'true'">
              <button
                mat-button
                class="make-appointment-button"
                (click)="onMakeAppointment()"
                (keydown.enter)="onMakeAppointment()"
                [disableRipple]="true"
              >
                MAKE AN APPOINTMENT
              </button>
            </div>
            <div *ngIf="this.outPatientEnabled === 'false'">
              <atlas-no-scheduling-text [colorBlue]="true"></atlas-no-scheduling-text>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
