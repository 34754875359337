<div *ngIf="!isNewproviderProfileExperience">
  <div
    *ngIf="this.commentsAndRatings?.ratings.length > 0"
    class="provider-ratings-container star-rating-container"
  >
    <div class="ratings-left">
      <h3>Ratings</h3>
      <div class="mobile-columns" *ngFor="let rating of commentsAndRatings.ratings">
        <div>
          <span class="rating-value">{{ rating.ratingValue }}</span>
          <span class="material-icons-outlined">{{
            rating.ratingValue | starRating: rating.ratingValue
          }}</span>
        </div>

        <p class="roboto-regular">{{ rating.ratingName }}</p>
      </div>
      <p class="subtext">
        Unlike websites where anyone can post doctor reviews, only actual patients can provide
        information by completing the survey sent to them.
        <a href="https://www.ahn.org/about/about-reviews.html" target="_blank">Learn more.</a>
      </p>
    </div>
    <div
      *ngIf="commentsAndRatings.comments.length > 0"
      class="rating-right margin-top-ratings-block"
    >
      <div class="rating-right-margin">
        <h3>Recent Patient Comments</h3>
        <div *ngFor="let comment of commentsAndRatings.comments | slice: 0 : 10; let i = index">
          <p class="roboto-regular">"{{ comment.commentText | specialPipe }}"</p>
          <p class="bump-up roboto-bold">{{ comment.commentDate }}</p>
        </div>
        <div class="view-comments-link" *ngIf="viewMoreComments">
          <a
            (click)="redirectToCommentsPage()"
            (keydown.enter)="redirectToCommentsPage()"
            aria-label="View All Comments"
            tabindex="0"
            >View All Comments</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isNewproviderProfileExperience">
  <div
    *ngIf="this.commentsAndRatings?.ratings.length > 0"
    class="provider-ratings-container-new star-rating-container-new"
  >
    <h3>{{ 'Patient Satisfaction Reviews (' + ratingsCount + ')' }}</h3>
    <p>
      Survey questions address the doctor’s compassion, communication style, and the likelihood the
      patient would recommend the doctor. Responses are measured on a scale of 1 to 5 with 5 being
      the best score.
    </p>
    <div class="">
      <div class="h3-wrapper">
        <h3 class="left-h3">Overall</h3>
        <h3 class="right-h3">{{ totalRatingValue + ' out of 5' }}</h3>
      </div>
      <div *ngFor="let rating of commentsAndRatings.ratings">
        <hr />
        <div class="mobile-columns">
          <p class="roboto-regular">{{ rating.ratingName }}</p>
          <div class="rating-value-container">
            <span class="material-icons-outlined">{{
              rating.ratingValue | starRating: rating.ratingValue
            }}</span>
            <span class="rating-value">{{ rating.ratingValue }}</span>
            <br />
          </div>
        </div>
      </div>
      <hr />
      <p class="subtext">
        At Allegheny Health Network, we partner with Press Ganey to collect patient feedback.
        Doctors who see patients in an office or clinic and have a reliable number of completed
        patient surveys will have reviews included on their profile.
        <a href="https://www.ahn.org/about/about-reviews.html" target="_blank"
          >Learn more about patient satisfaction.</a
        >
      </p>
      <hr />
    </div>
    <div
      *ngIf="commentsAndRatings.comments.length > 0"
      class="rating-right margin-top-ratings-block"
    >
      <div class="rating-right-margin">
        <h3>{{ 'Patient Comments (' + commentsAndRatings.comments.length + ')' }}</h3>
        <div class="comments-filter">
          <div *ngFor="let chip of commentChips">
            <button
              [ngClass]="
                commentFilter === chip.chipText
                  ? 'selected-comment-filter comment-filter-button'
                  : 'comment-filter-button'
              "
              *ngIf="chip.chipCount !== 0"
              (click)="selectCommentFilter(chip.chipText)"
            >
              <p class="comment-button-text">{{ chip.chipText }} ({{ chip.chipCount }})</p>
            </button>
          </div>
        </div>
        <div *ngFor="let comment of showComments">
          <div
            *ngIf="
              commentFilter === '' || commentCheck(comment.commentText, commentFilter)
            "
          >
            <p class="bump-up roboto-bold">{{ comment.commentDate }}</p>
            <p class="roboto-regular" [innerHTML]="comment.commentText | bold: commentFilter">
              <!-- {{ comment.commentText | bold:commentFilter }} -->
            </p>

            <hr />
            <br />
          </div>
        </div>
        <button
          *ngIf="viewMoreComments"
          mat-button
          class="view-comments-button"
          (click)="loadShowComments()"
          (keydown.enter)="loadShowComments()"
          aria-label="View All Comments"
          tabindex="0"
        >
          <div class="view-comments-button-text">Show More Comments</div>
        </button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="this.commentsAndRatings?.ratings.length === 0">
  <h3>No patient satisfaction reviews yet</h3>
  <p>We appreciate that patients have a lot of choices when it comes to where they receive their healthcare and who they want to provide it. It is our goal at Allegheny Health Network to provide not just exceptional care, but tools to help patients decide where to receive the best care for 
them.</p>
</div>
<div *ngIf="this.commentsAndRatings?.comments.length === 0">
  <h3>No patient comments yet</h3>
  <p>Patient comments are completely optional. Your feedback helps us improve the quality of care we provide.</p>
</div>
